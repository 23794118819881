// @codekit-prepend "utils.js"

/*--------------------------
 *
 *  THROTTLE FUNCTION
 *
 *--------------------------*/
function throttle(func, interval) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = false;
		};
		if (!timeout) {
			func.apply(context, args);
			timeout = true;
			setTimeout(later, interval);
		}
	};
}
jQuery(function ($) {
	window.onload = function () {
		//$('body').removeClass('is-loading');
		document.body.classList.remove("is-loading");
	};

	function msieversion() {
		var ua = window.navigator.userAgent;
		var msie = ua.indexOf("MSIE ");
		if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
			// If Internet Explorer, return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)));
		} // If another browser, return 0
		else {
			return false;
		}
		return false;
	}
	// isMobile var
	//const isMobile = $(window).innerWidth() <= 1024 ? true : false;

	var md = new MobileDetect(window.navigator.userAgent);

	var isMobile = function () {
		return $.browser.mobile || md.mobile() ? true : false;
	};

	if (isMobile()) {
		$("body").addClass("is-mobile");
	}

	/* -------------------------------------------------------------------------- */
	/*                                Notice popup                                */
	/* -------------------------------------------------------------------------- */
	var $noticeButton = $(".notice-button .button");

	$(window).on("load", function () {
		if ($noticeButton.length) {
			var noticeCookie = Cookies.get("shiseido_popup");
			if (!noticeCookie) {
				setTimeout(function () {
					$(".notice-popup").fadeIn();
				}, 1000);
			}

			$noticeButton.on("click", function () {
				Cookies.set("shiseido_popup", "1", { expires: 10 });
				$(".notice-popup").fadeOut();
			});
		}
	});
	/*--------------------------
	 *
	 *  DISABLE RIGHT CLICK ON IMAGES
	 *
	 *--------------------------*/
	$("img").on("contextmenu", function (e) {
		e.preventDefault();
	});

	/*--------------------------
	 *
	 *  ADD rel="noopener" TO EXTERNAL LINKS
	 *
	 *--------------------------*/
	function add_target_blank_to_external_links() {
		$('a[href^="http"]')
			.not('a[href*="' + location.hostname + '"]')
			.attr({
				target: "_blank",
				rel: "noopener",
			});
	}
	add_target_blank_to_external_links();
	/*--------------------------
	 *
	 *  LARGE SCREEN CLASS - VISIBLE SIDEBAR
	 *
	 *--------------------------*/

	$(window).on("load", function () {
		if ($(window).innerWidth() > 1399) {
			$("body").addClass("is-large-screen");
		} else {
			$("body").removeClass("is-large-screen");
		}
	});
	$(window).on("resize", function () {
		if ($(window).innerWidth() > 1399) {
			$("body").addClass("is-large-screen");
		} else {
			$("body").removeClass("is-large-screen");
		}
	});

	/*--------------------------
	 *
	 *  CLIPBOARD.JS
	 *
	 *--------------------------*/
	var clipboard = new ClipboardJS(".clipboard-copy");

	clipboard.on("success", function (e) {
		var $clipEl = $(e.trigger);
		// `content` is the JavaScript form of the `data-tippy-content` HTML attribute
		var content =
			'<div class="clipboard-tooltip">Link copied to clipboard <i class="fal fa-check-circle"></i></div>';
		tippy(e.trigger, {
			content: content,
			size: "small",
			interactiveBorder: 20,
			onHidden: function (instance) {
				instance.destroy();
			},
		});

		e.clearSelection();
	});

	clipboard.on("error", function (e) {
		console.error("Action:", e.action);
		console.error("Trigger:", e.trigger);
	});

	/*--------------------------
	 *
	 *  HERO HEADER - VIDEO MUTE TOGGLE
	 *
	 *--------------------------*/
	$("body").on("click", ".hero-video-mute-toggle", function () {
		var $toggle = $(this);
		var $videoMute = $toggle.next("video");
		if ($videoMute.length) {
			var videoMute = $videoMute.get(0),
				isMuted = videoMute.muted;
			if (isMuted) {
				videoMute.muted = false;
				$toggle.addClass("is-unmuted");
			} else {
				videoMute.muted = true;
				$toggle.removeClass("is-unmuted");
			}
		}
	});

	/*--------------------------
	 *
	 *  HERO PARENT HEADER - PARALLAX
	 *
	 *--------------------------*/
	var checkParallaxScroll = false;
	var isParallaxHidden = false;
	$(document).ready(function () {
		if ($(".container-blocks").first().hasClass("page-parent") && !isMobile()) {
			var $containerBlocks = $(".container-blocks").first();
			if ($containerBlocks.children().first().hasClass("hero")) {
				var $parallaxHero = $containerBlocks.children().first();
				$containerBlocks.addClass("container-blocks-parallax");
				$containerBlocks.next(".container-blocks").addClass("is-snapping");
				$parallaxHero.addClass("hero-parallax");
				$("#main, body").addClass("is-parallax");

				$(window).on(
					"scroll",
					throttle(function () {
						if (
							$(window).scrollTop() > $(window).innerHeight() * 3 &&
							!isParallaxHidden
						) {
							$containerBlocks.css("visibility", "hidden");
							isParallaxHidden = true;
						} else {
							if (isParallaxHidden) {
								$containerBlocks.css("visibility", "visible");
								isParallaxHidden = false;
							}
						}
					}, 250)
				);
			}
		}
	});

	/*--------------------------
	 *
	 *  CAROUSEL - NORMAL + PROGRESS
	 *
	 *--------------------------*/

	$(document).ready(function () {
		var time = 8;

		$(".slider").each(function () {
			var $slider = $(this);
			if ($slider.find(".slider-image").length > 1) {
			}
			var sliderDuration = $slider.attr("data-duration")
				? $slider.attr("data-duration")
				: "5000";
			time = sliderDuration / 1000;
			var totalSlides = $slider.find(".slider-image").length;
			var $bar = $slider.next(".slider-progress").find(".progress");
			var $wrapper = $slider.parent(".block-slider-container");
			if (!$wrapper.length) {
				var $wrapper = $slider.parent(".block-slider-quotes-container");
			}
			if (!$wrapper.length) {
				var $wrapper = $slider.parent(".block-slider-type-container");
			}
			// QUOTE SLIDER
			if (
				$slider.parent(".block-slider-quotes-container").length ||
				$slider.parent(".block-slider-type-container").length
			) {
				var $next = $slider
					.siblings(".slider-bottom")
					.find(".slider-arrows .arrow-next");
				var $prev = $slider
					.siblings(".slider-bottom")
					.find(".slider-arrows .arrow-prev");
			} else {
				// IMAGE SLIDER
				var $next = $slider.find(".arrow-next");
				var $prev = $slider.find(".arrow-prev");
			}
			var $count = $slider.siblings(".slider-bottom").find(".slider-count");
			var $title = $slider.siblings(".slider-bottom").find(".slider-title");
			var isPause, tick, percentTime;

			$slider.flickity({
				wrapAround: true,
				draggable: false,
				prevNextButtons: false,
				pageDots: false,
				lazyLoad: 2,
				imagesLoaded: true,
				cellSelector: ".slider-image",
				setGallerySize: true,
				on: {
					ready: function () {
						$title.html($slider.find(".slider-image.is-selected").attr("data-title"));
						$count.html("1/" + totalSlides);
					},
					change: function (index) {
						$count.html(+index + 1 + "/" + totalSlides);
						$title.html($slider.find(".slider-image.is-selected").attr("data-title"));
						$slider.find(".slider-image.is-video").each(function () {
							var dis = $(this);
							if (!dis.hasClass("is-selected")) {
								dis.find("video").get(0).pause();
							}
						});
					},
				},
			});

			$wrapper.on({
				mouseenter: function () {
					isPause = true;
				},
				mouseleave: function () {
					isPause = false;
				},
			});
			$next.on("click", function () {
				$slider.flickity("next", true);
				startProgressbar();
			});
			$prev.on("click", function () {
				$slider.flickity("previous", true);
				startProgressbar();
			});

			function startProgressbar() {
				resetProgressbar();
				percentTime = 0;
				isPause = false;
				tick = setInterval(interval, 10);
			}

			function interval() {
				if (isPause === false) {
					percentTime += 1 / (time + 0.1);
					$bar.css({
						width: percentTime + "%",
					});
					if (percentTime >= 100) {
						$slider.flickity("next", true);
						startProgressbar();
					}
				}
			}

			function resetProgressbar() {
				$bar.css({
					width: 0 + "%",
				});
				clearTimeout(tick);
			}

			var observer = new IntersectionObserver(
				function (entries) {
					// isIntersecting is true when element and viewport are overlapping
					// isIntersecting is false when element and viewport don't overlap
					if (entries[0].isIntersecting === true) {
						// When element enters viewport, autoplay starts
						startProgressbar();
					}
				},
				{
					threshold: [0],
				}
			);

			observer.observe($slider.get(0));

			//startProgressbar();
		});

		/*--------------------------
		 *
		 *  CAROUSEL - SWATCHES
		 *
		 *--------------------------*/

		$(".slider-swatches").each(function () {
			var $slider = $(this);
			var sliderDuration = $slider.attr("data-duration")
				? $slider.attr("data-duration")
				: "5000";
			time = sliderDuration / 1000;
			var totalSlides = $slider.find(".slider-image").length;
			var $bar = $slider.next(".slider-progress").find(".progress");
			var $wrapper = $slider.parent(".block-slider-swatches-container");
			var $next = $slider.siblings(".slider-bottom").find(".arrow-next");
			var $prev = $slider.siblings(".slider-bottom").find(".arrow-prev");
			var $count = $slider.siblings(".slider-bottom").find(".slider-count");
			var $title = $slider.siblings(".slider-bottom").find(".slider-title");
			var isPause, tick, percentTime;

			$slider.flickity({
				wrapAround: true,
				//freeScroll: true,
				prevNextButtons: false,
				pageDots: false,
				lazyLoad: 10,
				imagesLoaded: true,
				cellSelector: ".slider-image",
				resize: true,
				on: {
					ready: function () {
						$title.html($slider.find(".slider-image.is-selected").attr("data-title"));
						$count.html("1/" + totalSlides);
					},
					change: function (index) {
						$count.html(+index + 1 + "/" + totalSlides);
						$title.html($slider.find(".slider-image.is-selected").attr("data-title"));
					},
				},
			});
			setTimeout(function () {
				$slider.flickity("resize");
			}, 1000);
			$next.on("click", function () {
				$slider.flickity("next", true);
				startProgressbar();
			});
			$prev.on("click", function () {
				$slider.flickity("previous", true);
				startProgressbar();
			});

			// Intersection observer resize
			// 300px below viewport
			if (!!window.IntersectionObserver) {
				let observer = new IntersectionObserver(
					(entries, observer) => {
						entries.forEach((entry) => {
							if (entry.isIntersecting) {
								$(entry.target).flickity("resize");
								observer.unobserve(entry.target);
							}
						});
					},
					{ rootMargin: "0px 0px 300px 0px" }
				);
				observer.observe($slider[0]);
			}
		});
	});

	// Fix to reposition swatches slider
	// window.onload = function () {
	// 	window.dispatchEvent(new Event('resize'));
	// };

	/*--------------------------
	 *
	 *  TILE BLOCK
	 *
	 *--------------------------*/

	$(".block-tile-item")
		.not(".no-hover")
		// tile mouse actions
		.on("mouseenter", function () {
			$(this).children("img").css({
				transform: "scale(1.1)",
			});
		})
		.on("mouseleave", function () {
			$(this).children("img").css({
				transform: "none",
			});
		})
		.on("mousemove", function (e) {
			$(this)
				.children("img")
				.css({
					"transform-origin":
						((e.pageX - $(this).offset().left) / $(this).width()) * 100 +
						"% " +
						((e.pageY - $(this).offset().top) / $(this).height()) * 100 +
						"%",
				});
		});

	$(".block-tile-item")
		.on("mouseenter", function () {
			var altText = "";
			altText =
				$(this).children("img").attr("alt") ||
				$(this).children("img").attr("title");
			$(this).siblings(".block-tile-credits").find("span").html(altText);
		})
		.on("mouseleave", function () {
			$(this).siblings(".block-tile-credits").find("span").html("");
		});

	/*--------------------------
	 *
	 *  BLOCK - YES/NO MOUSEOVER
	 *
	 *--------------------------*/
	$(".block-yesno-item")
		.not(".no-hover")
		// tile mouse actions
		.on("mouseenter", function () {
			$(this).children(".yesno-image").find("img").css({
				transform: "scale(1.1)",
			});
		})
		.on("mouseleave", function () {
			$(this).children(".yesno-image").find("img").css({
				transform: "none",
			});
		})
		.on("mousemove", function (e) {
			$(this)
				.children(".yesno-image")
				.find("img")
				.css({
					"transform-origin":
						((e.pageX - $(this).offset().left) / $(this).width()) * 100 +
						"% " +
						((e.pageY - $(this).offset().top) / $(this).height()) * 100 +
						"%",
				});
		});

	/*--------------------------
	 *
	 *  BLOCK - JUXTAPOSITION
	 *
	 *--------------------------*/
	$(window).on("load", function () {
		$(".image-compare").cocoen().find("div").first().css("width", "66.66%");
	});

	/*--------------------------
	 *
	 *  BLOCK - HOTSPOTS
	 *
	 *--------------------------*/
	$(window).on("load", function () {
		$(".block-image-hotspots").each(function () {
			var lines = [];
			var $parent = $(this);
			var $container = $parent.find(".block-hotspots-container");
			var $items = $parent.find(".hotspot-item");
			if ($items.length) {
				var i = 1;
				$items.each(function () {
					var $dis = $(this);
					var hX = $dis.data("x");
					var hY = $dis.data("y");
					var hCol = $dis.data("color") ? $dis.data("color") : "color-white";
					var hText = $dis.data("content") ? $dis.data("content") : false;
					var hTooltip = hText ? ' data-tippy-content="' + hText + '"' : "";
					//$dis.css("top", hY).css("left", hX);
					$dis.css("left", hX);
					if (hX && hY) {
						$container.append(
							'<div class="hotspot hotspot-' +
								i +
								" " +
								hCol +
								'" data-number="' +
								i +
								'"' +
								hTooltip +
								"></div>"
						);
						$container
							.find(".hotspot-" + i)
							.css("top", hY)
							.css("left", hX);
					}
					i++;
				});
			}
		});
		$("body").on(
			"mouseenter mouseleave",
			".hotspot, .hotspots-content-list-item",
			function (e) {
				var $dis = $(this);
				var $disPar = $dis.parents(".block");
				var $hot = $disPar.find(".hotspot-" + $dis.attr("data-number"));
				var $li = $disPar.find(
					".hotspots-content-list-item-" + $dis.attr("data-number")
				);
				if (e.type == "mouseenter") {
					$hot.addClass("is-hover");
					$li.addClass("is-hover");
				} else {
					$hot.removeClass("is-hover");
					$li.removeClass("is-hover");
				}
			}
		);
	});

	/*--------------------------
	 *
	 *  HEADER MENU - HIDE ON SCROLL
	 *
	 *--------------------------*/

	// Hide Header on on scroll down
	var didScroll;
	var lastScrollTop = 0;
	var delta = 50;
	var navbar = $(".header");
	var navbarHeight = navbar.outerHeight();

	if (!isMobile()) {
		$(window).scroll(
			throttle(function (event) {
				didScroll = true;
			}, 250)
		);

		setInterval(function () {
			if (didScroll) {
				hasScrolled();
				didScroll = false;
			}
		}, 250);
	}

	function hasScrolled() {
		var st = $(window).scrollTop();

		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta) {
			return;
		}

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight) {
			// Scroll Down
			navbar.addClass("is-hidden");
			$("body").addClass("header-is-hidden");
		} else {
			// Scroll Up
			if (st + $(window).height() < $(document).height()) {
				navbar.removeClass("is-hidden");
				$("body").removeClass("header-is-hidden");
			}
		}

		lastScrollTop = st;
	}

	/*--------------------------
	 *
	 *  SCROLL CLASSES
	 *
	 *--------------------------*/
	$(window).on(
		"load scroll",
		throttle(function () {
			var docH = $(document).innerHeight();
			if ($(window).scrollTop() > $(window).innerHeight()) {
				$("body")
					.removeClass("scroll-is-top scroll-is-bottom")
					.addClass("scroll-not-top");
			} else {
				if ($(window).scrollTop() === 0) {
					$("body")
						.removeClass("scroll-is-bottom scroll-not-top")
						.addClass("scroll-is-top");
				} else {
					$("body")
						.removeClass("scroll-is-bottom scroll-is-top")
						.addClass("scroll-not-top");
				}
			}
		}, 250)
	);

	/*--------------------------
	 *
	 *  HEADER MENU - SIDEBAR
	 *
	 *--------------------------*/

	function menuOpen() {
		$(".menu-sidebar-toggle").addClass("is-active");
		$(".menu-sidebar").addClass("is-active");
		$("body").addClass("sidebar-is-active");
	}

	function menuClose() {
		$(".menu-sidebar-toggle").removeClass("is-active");
		$(".menu-sidebar").removeClass("is-active");
		$("body").removeClass("sidebar-is-active");
	}

	$(".menu-sidebar-toggle").on("click", function () {
		if ($(this).hasClass("is-active")) {
			menuClose();
		} else {
			menuOpen();
		}
	});

	/*--------------------------
	 *
	 *  SIDEBAR - SUB-SUB-MENU TOGGLE
	 *
	 *--------------------------*/

	$("body").on(
		"click",
		".sidebar-sub-section.menu-item-has-children > a",
		function (e) {
			e.preventDefault();
			var $dis = $(this);
			var $subSection = $dis.parent(".sidebar-sub-section.menu-item-has-children");
			var $subsubSection = $dis.next("ul.sub-menu");
			if (
				$subsubSection.hasClass("is-open") ||
				$dis.next("ul.sub-menu:visible").length
			) {
				$subsubSection.slideUp(function () {
					$subsubSection.removeClass("is-open");
				});
			} else {
				$subsubSection.slideToggle(function () {
					$subsubSection.toggleClass("is-open");
				});
			}
		}
	);

	/*--------------------------
	 *
	 *  SCROLLTO - SIDEBAR
	 *
	 *--------------------------*/
	$("body").on(
		"click",
		".sidebar-sub-sub-section.menu-item > a.scroll-to",
		function (e) {
			var dest = $(this).attr("data-scroll-to");
			var $dest = $(dest);
			if ($dest.length) {
				window.scrollTo({
					behavior: "auto",
					left: 0,
					top: $dest.offset().top,
				});
			}
		}
	);

	/*--------------------------
	 *
	 *  SIDEBAR - CHANGE TARGET
	 *
	 *--------------------------*/

	$("#menu-sidebar li.active.menu-item-has-children").each(function () {
		var $dis = $(this);
		var $submenu = $dis.find("ul.sub-menu");
		if ($submenu.length) {
			$submenu.find(".menu-item a").each(function () {
				var $a = $(this);
				var ahref = $a.attr("href");
				var parts = ahref.split("/");
				var lastSegment = parts.pop() || parts.pop(); // handle potential trailing slash
				var $atarget = $("a[name=" + lastSegment + "]");
				var atarget = "a[name=" + lastSegment + "]";

				let id = $a.attr("data-scroll-id");
				if (id) {
					$atarget = $("#pageid-" + id);
					atarget = "#pageid-" + id;
				}
				if ($atarget.length) {
					$a
						.removeAttr("href")
						.addClass("scroll-to")
						.attr("data-scroll-to", atarget);
				}
			});
		}
	});

	/*--------------------------
	 *
	 *  SIDEBAR - SUB-MENU INTRODUCTION ANCHOR
	 *
	 *--------------------------*/
	$(window).on("load", function () {
		$(".sidebar-sub-section.menu-item-has-children").each(function () {
			var $dis = $(this);
			var $a = $dis.find(">a");
			var $sub = $dis.find(">ul.sub-menu");
			if ($a.length && $sub.length) {
				// <li class="sidebar-sub-sub-section menu-item menu-our-logotype is-active"><a class="scroll-to" data-scroll-to="a[name=our-logotype]">Our Logotype</a></li>
				var insertA =
					'<li class="sidebar-sub-sub-section sidebar-sub-sub-intro"><a class="scroll-to" href="' +
					$a.attr("href") +
					'" data-scroll-to="' +
					$a.attr("data-scroll-to") +
					'">Introduction</a></li>';
				$a.removeClass("scroll-to");
				$sub.prepend(insertA);
			}
		});
		$("li.sidebar-sub-sub-intro > a").each(function () {
			var $dis = $(this);
			$dis.attr("href", $dis.attr("href").replace("undefined", ""));
			if ($dis.parents(".active").length) {
				$dis.removeAttr("href");
			}
		});
	});

	/*--------------------------
	 *
	 *  SCROLL TO
	 *
	 *--------------------------*/

	$("body").on("click", ".scroll-to", function () {
		// Do not scroll if it's a menu item

		if (
			!$(this).parent(".menu-item").length ||
			!$(this).siblings("li").length ||
			$(this).parents().find(".sidebar-main-section.active").length
		) {
			var dest = $(this).attr("data-scroll-to");
			var $dest = $(dest);
			if ($dest.length) {
				window.scrollTo({
					behavior: "auto",
					left: 0,
					top: $dest.offset().top,
				});
			}
		}
	});
	$(window).on("load", function () {
		var redir = $("html").attr("data-redirect");
		if (redir && redir != "false") {
			var $redir = $("a[name=" + redir + "]").length
				? $("a[name=" + redir + "]")
				: $('[data-slug="' + redir + '"]');
			if ($redir.length) {
				window.scrollTo({
					behavior: "auto",
					left: 0,
					top: $redir.offset().top,
				});
			}
		}
	});

	/*--------------------------
	 *
	 *  WAYPOINTS - SIDEBAR BREADCRUMB
	 *
	 *--------------------------*/

	var currentTitle,
		currentSlug,
		currentID,
		$breadcrumb = $(".sidebar-side-breadcrumb span"),
		$activeMenuParent = $("#menu-sidebar li.active");
	$(window).on("load", function () {
		// $(".section-marker").waypoint(
		// 	function(direction) {
		// 		var previousWaypoint = this.previous();
		// 		var nextWaypoint = this.next();
		// 		var $submenuCont = $(
		// 			"#menu-sidebar .menu-item-has-children.active ul.sub-menu"
		// 		);
		// 		if (direction === "down") {
		// 			/* ----------------------------- Scrolling down ----------------------------- */

		// 			currentID = $(this.element).attr("data-pageid");
		// 			currentTitle = $(this.element).attr("data-title");
		// 			currentSlug = $(this.element).attr("data-slug");
		// 			var parentSlugDown = $(this.element).attr("data-parent");

		// 			console.log("current id", currentID);
		// 			console.log("prentsludown", parentSlugDown);

		// 			$breadcrumb.fadeOut(function() {
		// 				$breadcrumb.html(currentTitle);
		// 				$breadcrumb.fadeIn();
		// 			});

		// 			// If current section is on the menu, highlight
		// 			// if ($submenuCont.find(".menu-" + currentSlug).length) { Disabled 2020 03 20

		// 			// Modified 2020 03 20
		// 			let $menuLink = $submenuCont.find("a[data-scroll-id=" + currentID + "]");
		// 			let $menuLinkParent = $menuLink.parent(".menu-item");

		// 			if ($menuLinkParent.length) {
		// 				$submenuCont.find(".menu-item.is-active").removeClass("is-active");
		// 				$menuLinkParent.addClass("is-active");
		// 				//$submenuCont.find(".menu-" + currentSlug).addClass("is-active");
		// 				var $act = $submenuCont.find(".is-active");
		// 				updateMenuState();
		// 				if (!$act.is(":in-viewport")) {
		// 					$act[0].scrollIntoView({
		// 						behavior: "auto",
		// 						block: "center",
		// 						inline: "nearest"
		// 					});
		// 				}
		// 			} else {
		// 				// If not, highlight menu parent
		// 				if (parentSlugDown) {
		// 					var $parentItem = $submenuCont.find(".menu-" + parentSlugDown);
		// 					if ($parentItem.length) {
		// 						$submenuCont.find(".menu-item.is-active").removeClass("is-active");
		// 						$parentItem.addClass("is-active");
		// 					}
		// 				}
		// 			}

		// 			// Check if there's no active menu item, and activate the parent
		// 			if (!$submenuCont.find(".menu-item.is-active").length) {
		// 				$activeMenuParent.addClass("is-active");
		// 			} else {
		// 				$activeMenuParent.removeClass("is-active");
		// 			}
		// 		} else {
		// 			/* ------------------------------ Scrolling up ------------------------------ */

		// 			var prevID =
		// 				null !== previousWaypoint
		// 					? $(previousWaypoint.element).attr("data-pageid")
		// 					: false;
		// 			console.log("up menu id", prevID);
		// 			var prevTitle =
		// 				null !== previousWaypoint
		// 					? $(previousWaypoint.element).attr("data-title")
		// 					: false;
		// 			var prevSlug =
		// 				null !== previousWaypoint
		// 					? $(previousWaypoint.element).attr("data-slug")
		// 					: "";
		// 			var parentSlug =
		// 				null !== previousWaypoint
		// 					? $(previousWaypoint.element).attr("data-parent")
		// 					: "";
		// 			if (prevTitle) {
		// 				$breadcrumb.fadeOut(function() {
		// 					$breadcrumb.html(prevTitle);
		// 					$breadcrumb.fadeIn();
		// 				});

		// 				let $menuLink = $submenuCont.find("a[data-scroll-id=" + prevID + "]");
		// 				let $menuLinkParent = $menuLink.parent(".menu-item");
		// 				// If current section is on the menu, highlight
		// 				if ($menuLinkParent.length) {
		// 					$submenuCont.find(".menu-item.is-active").removeClass("is-active");
		// 					$menuLinkParent.addClass("is-active");
		// 					updateMenuState();
		// 					var $act = $submenuCont.find(".is-active");
		// 					updateMenuState();
		// 					if (!$act.is(":in-viewport")) {
		// 						$act[0].scrollIntoView({
		// 							behavior: "auto",
		// 							block: "center",
		// 							inline: "nearest"
		// 						});
		// 					}
		// 				} else {
		// 					// If not, highlight parent menu item
		// 					if (parentSlug) {
		// 						var $parentItem = $submenuCont.find(".menu-" + parentSlug);
		// 						if ($parentItem.length) {
		// 							$submenuCont.find(".menu-item.is-active").removeClass("is-active");
		// 							$parentItem.addClass("is-active");
		// 						}
		// 					}
		// 				}

		// 				// Check if there's no active menu item, and activate the parent
		// 				if (!$submenuCont.find(".menu-item.is-active").length) {
		// 					$activeMenuParent.addClass("is-active");
		// 				} else {
		// 					$activeMenuParent.removeClass("is-active");
		// 				}
		// 			}
		// 		}
		// 	},
		// 	{
		// 		offset: "50%"
		// 	}
		// );

		/* -------------------------------------------------------------------------- */
		/*                     WAYPOINTS DOWN - NEW MARCH 03 2020                     */
		/* -------------------------------------------------------------------------- */
		const waypoints_down = $(".section-marker").waypoint(
			function (direction) {
				if (direction === "up") {
					return false;
				}
				var previousWaypoint = this.previous();
				var nextWaypoint = this.next();
				var $submenuCont = $(
					"#menu-sidebar .menu-item-has-children.active ul.sub-menu"
				);
				if (direction === "down") {
					/* ----------------------------- Scrolling down ----------------------------- */

					currentID = $(this.element).attr("data-pageid");
					currentTitle = $(this.element).attr("data-title");
					currentSlug = $(this.element).attr("data-slug");
					var parentSlugDown = $(this.element).attr("data-parent");

					// console.log("current id", currentID);
					// console.log("prentsludown", parentSlugDown);

					$breadcrumb.fadeOut(function () {
						$breadcrumb.html(currentTitle);
						$breadcrumb.fadeIn();
					});

					// If current section is on the menu, highlight
					// if ($submenuCont.find(".menu-" + currentSlug).length) { Disabled 2020 03 20

					// Modified 2020 03 20
					let $menuLink = $submenuCont.find("a[data-scroll-id=" + currentID + "]");
					let $menuLinkParent = $menuLink.parent(".menu-item");

					if ($menuLinkParent.length) {
						$submenuCont.find(".menu-item.is-active").removeClass("is-active");
						$menuLinkParent.addClass("is-active");
						//$submenuCont.find(".menu-" + currentSlug).addClass("is-active");
						var $act = $submenuCont.find(".is-active");
						updateMenuState();
						if (!$act.is(":in-viewport")) {
							$act[0].scrollIntoView({
								behavior: "auto",
								block: "center",
								inline: "nearest",
							});
						}
					} else {
						// If not, highlight menu parent
						if (parentSlugDown) {
							var $parentItem = $submenuCont.find(".menu-" + parentSlugDown);
							if ($parentItem.length) {
								$submenuCont.find(".menu-item.is-active").removeClass("is-active");
								$parentItem.addClass("is-active");
							}
						}
					}

					// Check if there's no active menu item, and activate the parent
					if (!$submenuCont.find(".menu-item.is-active").length) {
						$activeMenuParent.addClass("is-active");
					} else {
						$activeMenuParent.removeClass("is-active");
					}
				}
			},
			{
				offset: "25%",
			}
		);

		const waypoints_up = $(".section-marker").waypoint(
			function (direction) {
				if (direction === "down") {
					return false;
				}
				var previousWaypoint = this.previous();
				var nextWaypoint = this.next();
				var $submenuCont = $(
					"#menu-sidebar .menu-item-has-children.active ul.sub-menu"
				);
				var prevID =
					null !== previousWaypoint
						? $(previousWaypoint.element).attr("data-pageid")
						: false;
				console.log("up menu id", prevID);
				var prevTitle =
					null !== previousWaypoint
						? $(previousWaypoint.element).attr("data-title")
						: false;
				var prevSlug =
					null !== previousWaypoint
						? $(previousWaypoint.element).attr("data-slug")
						: "";
				var parentSlug =
					null !== previousWaypoint
						? $(previousWaypoint.element).attr("data-parent")
						: "";
				if (prevTitle) {
					$breadcrumb.fadeOut(function () {
						$breadcrumb.html(prevTitle);
						$breadcrumb.fadeIn();
					});

					let $menuLink = $submenuCont.find("a[data-scroll-id=" + prevID + "]");
					let $menuLinkParent = $menuLink.parent(".menu-item");
					// If current section is on the menu, highlight
					if ($menuLinkParent.length) {
						$submenuCont.find(".menu-item.is-active").removeClass("is-active");
						$menuLinkParent.addClass("is-active");
						updateMenuState();
						var $act = $submenuCont.find(".is-active");
						updateMenuState();
						if (!$act.is(":in-viewport")) {
							$act[0].scrollIntoView({
								behavior: "auto",
								block: "center",
								inline: "nearest",
							});
						}
					} else {
						// If not, highlight parent menu item
						if (parentSlug) {
							var $parentItem = $submenuCont.find(".menu-" + parentSlug);
							if ($parentItem.length) {
								$submenuCont.find(".menu-item.is-active").removeClass("is-active");
								$parentItem.addClass("is-active");
							}
						}
					}

					// Check if there's no active menu item, and activate the parent
					if (!$submenuCont.find(".menu-item.is-active").length) {
						$activeMenuParent.addClass("is-active");
					} else {
						$activeMenuParent.removeClass("is-active");
					}
				}
			},
			{
				offset: "75%",
			}
		);
	});

	// When scrolling to a section,
	// checks if that sub-menu is closed, then open it
	function updateMenuState() {
		var $subsubActive = $(".menu-item.sidebar-sub-sub-section.is-active");

		if ($subsubActive.length) {
			var $subsubParent = $subsubActive.parent("ul.sub-menu");
			$("ul.sub-menu").removeClass("is-auto-open");
			$subsubParent.addClass("is-auto-open");
		}
	}

	/*--------------------------
	 *
	 *  AOS
	 *
	 *--------------------------*/

	$(document).ready(function () {
		var tot = $(".container-blocks").length;
		if (tot == 1 && !$(".hero").length) {
			$(".container-blocks").addClass("is-only-one");
		}
	});
	$(window).on("load", function () {
		AOS.init({
			duration: 1000,
			easing: "easeOutQuart",
			once: true,
			anchorPlacement: "center-bottom",
			disable: "tablet",
		});
	});

	$(window).on("load", function () {
		$("#loader").fadeOut("slow");
		$("body").removeClass("is-loading");
	});
	$(window).on("beforeunload", function (e) {
		$("#loader").fadeIn("slow");
		$("body").addClass("is-loading");
	});

	/*--------------------------
	 *
	 *  TOOLTIPS
	 *
	 *--------------------------*/
	$(document).ready(function () {
		$.getJSON(
			"https://shiseidobrandcode.com/wp-json/custom/v1/glossary",
			function (data) {
				var items = [];
				$.each(data, function (key, val) {
					items[val.id] = val.content;
					var contentLink =
						val.content +
						' <a class="glossary-link" href="' +
						val.link +
						'"><span>Go to Glossary</span></a>';
					$('span.glossary-tooltip[data-glossary-tooltip-id="' + val.id + '"]').each(
						function () {
							$(this)
								.attr("data-tippy-content", contentLink)
								.attr("data-glossary-url", val.link);
							//$(this).html('<a class="glossary-link" href="' + val.link + '">' + $(this).html() + '</a>');
						}
					);
				});
			}
		);
	});
	var tip;
	$(window).on("load", function () {
		tippy.setDefaults({
			animation: "fade",
			arrow: true,
			interactive: true,
			distance: 20,
			maxWidth: "540px",
		});
		setTimeout(function () {
			tip = tippy("[data-tippy-content]");
		}, 1500);
	});

	/*--------------------------
	 *
	 *  ASSETS + GLOSSARY - ARCHIVE SORT
	 *
	 *--------------------------*/

	var assetsList, glossaryList, sort_order;

	function asc_desc(column) {
		if (sort_order[column.innerText] === "asc") {
			sort_order[column.innerText] = "desc";
			column.setAttribute("data-order", "desc");
		} else {
			sort_order[column.innerText] = "asc";
			column.setAttribute("data-order", "asc");
		}
	}

	if ($("#assets-list").length) {
		var valueNames = [
			"name",
			"format",
			{
				name: "update",
				attr: "data-timestamp",
			},
		];
		var listOptions = {
			valueNames: valueNames,
		};
		sort_order = {
			name: "asc",
			format: "asc",
			update: "asc",
		};

		assetsList = new List("assets-list", listOptions);
	}

	if ($("#glossary-list").length) {
		var valueNames = ["name", "definition"];
		var listOptions = {
			valueNames: valueNames,
		};
		sort_order = {
			name: "asc",
			definition: "asc",
		};

		glossaryList = new List("glossary-list", listOptions);
	}

	/*--------------------------
	 *
	 *  ASSETS - BUTTON DROPDOWN
	 *
	 *--------------------------*/

	function DropDown(el) {
		this.dd = el;
		this.placeholder = this.dd.children("span");
		this.opts = this.dd.find("ul.button-dropdown-dropdown > li");
		this.val = "";
		this.index = -1;
		this.initEvents();
	}
	DropDown.prototype = {
		initEvents: function () {
			var obj = this;

			obj.dd.on("click", function (event) {
				if (
					!$(event.target).attr("href") &&
					!$(event.target).parent("a").attr("href")
				) {
					$(this).toggleClass("active");
					return false;
				}
			});
		},
		getValue: function () {
			return this.val;
		},
		getIndex: function () {
			return this.index;
		},
	};

	$(".button-dropdown").each(function () {
		new DropDown($(this));
	});
	var dd = new DropDown($("#dd"));

	$(document).click(function () {
		// all dropdowns
		$(".button-dropdown").removeClass("active");
	});

	/*--------------------------
	 *
	 *  SEARCH FORM - AJAX
	 *
	 *--------------------------*/

	$(".search-button").on("click", function () {
		if ($("body").hasClass("search-form-active")) {
			// Cerrar búsqueda
			searchClose();
		} else {
			// Abrir
			searchOpen();
		}
	});

	$(document).on("keyup", function (e) {
		if ($("body").hasClass("search-form-active")) {
			if (e.keyCode == 27) {
				searchClose();
			}
		}
	});

	function searchClose() {
		$(".search-button").removeClass("is-active");
		$("#ajax-search").fadeOut(function () {
			$("body").removeClass("search-form-active");
			$("#search-dump").removeClass("is-active");
			$("#search-dump").find(".results-list").remove();
			$(".search-results-container").removeClass("is-active");
		});
	}

	function searchOpen() {
		$("body").addClass("search-form-active");
		$(".search-button").addClass("is-active");
		$("#ajax-search").fadeIn(function () {
			$("#searchform").find("#s").focus();
		});
	}

	$(document).on("submit", ".search-form-container #searchform", function (e) {
		e.preventDefault();
		var $form = $(this);
		var $input = $form.find('input[name="s"]');
		var query = $input.val();
		var $content = $(".search-results-container");
		var $sContainer = $("#search-dump");
		var $icon = $(".search-icon").find(".icon");

		$.ajax({
			type: "post",
			url: searchAjax.ajaxurl,
			data: {
				action: "load_search_results",
				query: query,
			},
			beforeSend: function () {
				$input.prop("disabled", true);
				$sContainer.addClass("is-loading");
				$sContainer.addClass("is-active");
			},
			success: function (response) {
				setTimeout(function () {
					$input.prop("disabled", false);
					$sContainer.removeClass("is-loading");
					$content.html(response).addClass("is-active");
				}, 1000);
			},
		});

		return false;
	});

	// ------------
	// Close on click outside of container
	$("#ajax-search").on("click", function (e) {
		if ($(e.target).hasClass("background")) {
			searchClose();
		}
	});
});
